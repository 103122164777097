// import {
//   faHome,
//   faSearch,
//   faUser,
//   faUsers,
//   faEye,
//   faEyeSlash,
//   faFile,
//   faFileVideo,
//   faFileWord,
//   faFileExcel,
//   faFilePowerpoint,
//   faFilePdf,
//   faUpload,
//   faFilter,
//   faCalendarAlt,
//   faGripVertical
// } from '@fortawesome/pro-solid-svg-icons';

const icons = [
  // faHome,
  // faSearch,
  // faUser,
  // faUsers,
  // faEye,
  // faEyeSlash,
  // faFile,
  // faFileVideo,
  // faFileWord,
  // faFileExcel,
  // faFilePowerpoint,
  // faFilePdf,
  // faUpload,
  // faFilter,
  // faCalendarAlt,
  // faGripVertical
];

/*kingscode icons*/
const identifiers = {
  home: ['fas', 'home'],
  profile: ['fas', 'user'],
  search: ['fas', 'search'],
  upload: ['fas', 'upload'],
  filter: ['fas', 'filter'],

  user: ['fas', 'user'],
  users: ['fas', 'users'],
  visible: ['fas', 'eye'],
  invisible: ['fas', 'eye-slash'],
  fileUnknown: ['fas', 'file'],
  fileVideo: ['fas', 'file-video'],
  fileWord: ['fas', 'file-word'],
  fileExcel: ['fas', 'file-excel'],
  filePowerpoint: ['fas', 'file-powerpoint'],
  filePdf: ['fas', 'file-pdf'],
  grip: ['fas', 'grip-vertical'],

  date: ['fas', 'calendar-alt'],
};

export default {
  icons,
  identifiers,
};
