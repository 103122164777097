// import {
//   faMinus,
//   faPlus,
//   faGripVertical,
//   faPaperclip,
//   faArrowsAltV,
//   faStepForward,
//   faStepBackward,
//   faSync,
//   faStar,
//   faStarHalf,
//   faPencilAlt,
//   faCircle,
//   faDotCircle,
//   faCaretDown,
//   faQuestionCircle,
//   faBars,
//   faChevronDown,
//   faSortUp,
//   faMinusSquare,
//   faCheckSquare,
//   faChevronRight,
//   faChevronLeft,
//   faExclamationTriangle,
//   faExclamation,
//   faInfoCircle,
//   faCheckCircle,
//   faTimesCircle,
//   faCheck,
//   faComment,
//   faDownload,
// } from '@fortawesome/pro-solid-svg-icons';

// import {
//   faSquare,
//   faTimes
// } from '@fortawesome/pro-regular-svg-icons';

const icons = [
  // faMinus,
  // faPlus,
  // faGripVertical,
  // faPaperclip,
  // faArrowsAltV,
  // faStepForward,
  // faStepBackward,
  // faSync,
  // faStar,
  // faStarHalf,
  // faQuestionCircle,
  // faPencilAlt,
  // faCircle,
  // faDotCircle,
  // faCaretDown,
  // faBars,
  // faChevronDown,
  // faSortUp,
  // faMinusSquare,
  // faSquare,
  // faCheckSquare,
  // faChevronRight,
  // faChevronLeft,
  // faExclamationTriangle,
  // faExclamation,
  // faInfoCircle,
  // faCheckCircle,
  // faTimes,
  // faTimesCircle,
  // faCheck,
  // faComment,
  // faDownload,
];

/*vuetify default icons*/
const identifiers = {
  complete: ['fas', 'check'],
  cancel: ['far', 'times'],
  close: ['far', 'times'],
  delete: ['far', 'times'],
  download: ['fas', 'download'],
  clear: ['far', 'times'],
  success: ['fas', 'check-circle'],
  info: ['fas', 'info-circle'],
  warning: ['fas', 'exclamation'],
  error: ['fas', 'exclamation-triangle'],
  prev: ['fas', 'chevron-left'],
  next: ['fas', 'chevron-right'],
  checkboxOn: ['fas', 'check-square'],
  checkboxOff: ['far', 'square'],
  checkboxIndeterminate: ['fas', 'minus-square'],
  delimiter: ['fas', 'circle'],
  sort: ['fas', 'sort-up'],
  expand: ['fas', 'chevron-down'],
  menu: ['fas', 'bars'],
  subgroup: ['fas', 'caret-down'],
  dropdown: ['fas', 'caret-down'],
  radioOn: ['fas', 'dot-circle'],
  radioOff: ['fas', 'circle'],
  edit: ['fas', 'pencil-alt'],
  ratingEmpty: ['fas', 'star'],
  ratingFull: ['fas', 'star'],
  ratingHalf: ['fas', 'star-half'],
  loading: ['fas', 'sync'],
  first: ['fas', 'step-backward'],
  last: ['fas', 'step-forward'],
  unfold: ['fas', 'arrows-alt-v'],
  unknown: ['fas', 'question-circle'],
  errorCircle: ['fas', 'times-circle'],
  file: ['fas', 'paperclip'],
  plus: ['fas', 'plus'],
  minus: ['fas', 'minus'],
  comment: ['fas', 'comment'],
  draggable: ['fas', 'grip-vertical'],
};

export default { icons, identifiers, };
